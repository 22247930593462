<template>
  <div class="content">
    <PageHeader title="Expediente" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <router-link
            to="/live/configs/opening-hours/create"
            class="btn btn-success float-right"
            tag="button"
          >
            <i class="fas fa-plus color-white"></i>
            <span class="hide-mobile">Adicionar horário</span>
          </router-link>
        </div>
        <div class="card-body p-0">
          <div class="static qt-block-ui p-2" v-if="loading"></div>
          <div class="p-4" v-else-if="!openinghours || !openinghours.length">
            <div class="text-center table-placeholder">
              <i class="fas fa-clock font-size-80"></i>
              <h5 class="card-title m-t-20">
                Não há horários de expediente cadastrados
              </h5>
            </div>
          </div>
          <template v-else>
            <table class="table">
              <thead class="bg-light">
                <tr>
                  <th>Departamento</th>
                  <th class="p-l-20">Começa</th>
                  <th class="p-l-20">Termina</th>
                  <th class="p-l-20">Semana</th>
                  <th class="p-l-20"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(hours, i) in openinghours">
                  <tr role="button" :key="hours.id">
                    <td>{{hours.generic ? 'Geral' : hours.department_name}}</td>
                    <td>{{hours.begin_hour}}</td>
                    <td>{{hours.end_hour}}</td>
                    <td class="days">
                      <span class="badge badge-danger" v-if="hours.sunday">Dom</span>
                      <span class="badge badge-primary" v-if="hours.monday">Seg</span>
                      <span class="badge badge-primary" v-if="hours.tuesday">Ter</span>
                      <span class="badge badge-primary" v-if="hours.wednesday">Qua</span>
                      <span class="badge badge-primary" v-if="hours.thursday">Qui</span>
                      <span class="badge badge-primary" v-if="hours.friday">Sex</span>
                      <span class="badge badge-warning" v-if="hours.saturday">Sáb</span>
                    </td>
                    <td>
                      <div class="dropdown">
                        <a
                          role="button"
                          class="dropdown-action"
                          :to="'#menu-dropdown-' + i"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="icon dripicons-gear text-success"></i>
                        </a>
                        <div
                          class="dropdown-menu dropdown-menu-right"
                          :id="'menu-dropdown-' + i"
                          x-placement="bottom-end"
                          style
                        >
                          <!-- <a class="dropdown-item emit-j-even" @click="edit(hours.id)">
                            Editar
                          </a> -->
                          <a class="dropdown-item" @click="remove(hours.id)">
                            Remover
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Emojis from '@/components/Emojis.vue';
import PageHeader from '@/components/PageHeader.vue';
// import { ToggleButton } from 'vue-js-toggle-button';
import axios from '@/plugins/axios';
import Swal from 'sweetalert2';

export default {
  name: 'Responses',
  components: {
    // InfiniteLoading,
    // Emojis,
    PageHeader,
    // ToggleButton,
  },
  data() {
    return {
      loading: true,
      departments: [],
      openinghours: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  mounted() {
    this.fetch();
  },
  destroyed() {
  },
  methods: {
    fetch() {
      axios().get('webchat/openinghours').then((response) => {
        this.loading = false;
        console.log('OpeningHours', response.data);
        this.openinghours = response.data;
      }).catch((error) => {
        this.loading = false;
        console.error('OpeningHours', error);
      });
    },
    remove(id) {
      Swal.fire({
        title: 'Tem certeza?',
        text: 'Deseja deletar esse horário permanentemente. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          axios().delete(`webchat/openinghours/${id}`).then((response) => {
            this.loading = false;
            Swal.fire('Excluído!', 'Horário excluído', 'success');
            this.fetch();
          }, (error) => {
            this.loading = false;
            this.$toast.show({
              title: 'Erro',
              content: error.message,
              type: 'danger',
            });
          });
        }
      });
    },
    edit(id) {
      this.$router.push({ name: 'CreateEditOpeningHour', params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.qt-block-ui {
  min-height: 200px;
}

.days {
  .badge {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
